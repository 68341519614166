<template>
  <article class="play" aria-haspopup="true" tabindex="1">
    <ul class="images">
      <li class="image">
        <img :src="play.poster_path" alt="" />
      </li>
      <li class="image">
        <img :src="play.backdrop_path" alt="" />
      </li>
    </ul>
    <div class="bg">
      <div class="content">
        <h1 class="title">{{ play.name || play.title }}</h1>
        <p class="synopsis">{{ truncateText(play.overview) }}</p>
        <ul class="meta">
          <li class="type">{{ formatMediaType(play.media_type) }}</li>
          <li v-for="genre in play.genres" :key="genre" class="genre">
            {{ genre }}
          </li>
        </ul>
        <PlayButton :play="play" />
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from "vuex";
import PlayButton from "./Button.vue";
import { truncateText, formatMediaType } from "@/utils";

export default {
  props: {
    play: Object,
  },
  components: {
    PlayButton,
  },
  computed: {
    ...mapGetters(["genre"]),
  },
  methods: {
    truncateText,
    formatMediaType,
    openFullscreen(_, el = document.documentElement) {
      let rfs =
        el.requestFullscreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;

      if (typeof rfs != "undefined" && rfs) {
        rfs.call(el);
      } else if (typeof window.ActiveXObject != "undefined") {
        // eslint-disable-next-line no-undef
        var wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$card-height: 405.03px;
$card-width: 270px;

.play {
  /* background: grey; */
  /* width: $card-width !important; */
  width: Min(691px, 75vw);
  height: $card-height;
  display: flex;
  align-items: flex-end;
  border-radius: 8px;
  transition: 0.3s width ease, 0s background ease;
  transition-delay: 0.2s;
  overflow: hidden;
  text-align: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid white;
  position: relative;

  .images {
    position: absolute;
    height: 100%;
    width: 100%;
    /* overflow: hidden; */
    z-index: 0;
    transition: 0.5s all cubic-bezier(0.47, 0, 0.745, 0.715);

    .image {
      height: 100%;
      width: 100%;

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  &:hover {
    /* width: Min(691px, 75vw) !important; */

    .bg {
      transition: 0.1s all ease;
      transition-delay: 0.5s;
      opacity: 1;
      width: 100%;
    }

    .content {
      transform: translateY(0);
      transition: 0.8s all ease;
      transition-delay: 0.5s;
    }

    .images {
      transform: translateY(-100%);
    }
  }

  .bg {
    position: relative;
    z-index: 2;
    opacity: 0;
    background: linear-gradient(to top, rgba(black, 0.8) 80%, transparent);
  }

  .content {
    color: white;
    padding: 20px;
    transform: translateY(-20px);
    /* transition-delay: 0s; */
    transition-duration: 0.3s;

    .title {
      font-size: 1.8rem;
    }

    .synopsis {
      font-size: 1rem;
    }

    .meta {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      /* font-size: 1.6rem; */
      margin: -5px;
      margin-top: 10px;

      li {
        margin: 5px;
      }

      .type {
        border: 2px solid white;
        padding: 2px 5px;
        margin-right: 20px;
        text-transform: capitalize;
      }

      .genre {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          &:after {
            /* content: "\00B7"; */
            background: #e5e5e5;
            content: "";
            width: 8px;
            height: 8px;
            display: inline-block;
            border-radius: 50%;
            margin-left: 10px;
          }
        }
      }
    }

    .btn {
      all: unset;
      cursor: pointer;
      background: #e5e5e5;
      color: #3c4043;
      border-radius: 30px;
      /* font-size: 1.4rem; */
      padding: 10px 20px;
      display: inline-block;
      font-weight: 600;
      margin-top: 25px;
      transition: 0.2s all ease-in;

      &:hover {
        background: #3c4043;
        color: #e5e5e5;
      }
    }
  }

  @media (min-width: 768px) {
    $card-height: 460.9px;
    /* $card-width: 307.25px; */
    /* width: $card-width !important; */
    width: Min(691px, 75vw);
    height: $card-height;

    .title {
      font-size: 2.4rem !important;
    }

    .synopsis {
      font-size: 1.3rem !important;
    }

    .meta {
      font-size: 1.6rem;
      margin-top: 30px;
    }

    .btn {
      font-size: 1.4rem;
      padding: 10px 20px;
    }
  }
}
</style>
