import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

import {
  auth,
  userCollections,
  functions,
  storage,
  scriptCollections,
  preferencesCollections,
} from "../firebase-config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    plays: [],
    members: [],
    user: null,
    isAdmin: false,
    isAuth: false,
    settings: {
      pdfPageLimit: 6,
      playsLimit: 50,
    },
    query: null,
  },
  mutations: {
    SET_PLAYS(state, plays) {
      state.plays = plays;
    },
    MANAGE_PLAYS(state, { action, play }) {
      const playIndex = state.plays.findIndex((p) => p.id === play.id);
      switch (action) {
        case "modified":
          if (playIndex > -1) Vue.set(state.plays, playIndex, play);
          break;
        case "removed":
          if (playIndex > -1) state.plays.splice(playIndex, 1);
          break;
      }
    },
    SET_MEMBERS(state, members) {
      state.members = members;
    },
    MANAGE_MEMBERS(state, { action, member }) {
      const memberIndex = state.members.findIndex((p) => p.id === member.id);
      if (memberIndex < 0) return;
      switch (action) {
        case "modified":
          Vue.set(state.members, memberIndex, member);
          break;
        case "removed":
          state.members.splice(memberIndex, 1);
          break;
      }
    },
    SET_USER(state, user) {
      Vue.set(state, "user", user);
    },
    SET_ADMIN(state, boo) {
      state.isAdmin = boo;
    },
    SET_AUTH(state, boo) {
      state.isAuth = boo;
    },
    SET_SETTINGS(state, settings) {
      state.settings = settings;
    },
    SET_QUERY(state, query) {
      state.query = query;
    },
  },
  actions: {
    async getPlays({ commit }) {
      try {
        await scriptCollections.where("owner", "==", null).onSnapshot(
          (querySnapshot) => {
            if (!querySnapshot.empty) {
              let play = querySnapshot.docChanges()[0].doc.data();
              play.id = querySnapshot.docChanges()[0].doc.id;
              if (querySnapshot.docChanges()[0].type == "modified") {
                commit("MANAGE_PLAYS", { action: "modified", play });
              } else if (querySnapshot.docChanges()[0].type == "removed") {
                commit("MANAGE_PLAYS", { action: "removed", play });
              } else {
                const plays = [];
                querySnapshot.docs.forEach((doc) => {
                  let data = doc.data();
                  data.id = doc.id;
                  plays.push(data);
                });
                commit("SET_PLAYS", plays);
              }
            }
          },
          function (error) {
            throw error;
          }
        );
      } catch (err) {
        throw new Error(err);
      }
    },
    async signIn(_, { email, password }) {
      if (!email || !password) throw new Error("Invalid parameters");
      try {
        await auth.signInWithEmailAndPassword(email, password);
        if (!auth.currentUser.emailVerified)
          throw new Error(
            "Email not yet verified. Please verify email to sign in"
          );
      } catch (err) {
        throw new Error(err);
      }
    },
    async createNewUser({ dispatch }, signUpParams) {
      const { email, password, phoneNumber, displayName, admin } = signUpParams;
      if (!email || !password || !phoneNumber || !displayName)
        throw new Error("Invalid parameters");
      try {
        await auth.createUserWithEmailAndPassword(email, password);
        if (admin) {
          await dispatch("makeUserAdmin");
        }
        await userCollections.doc(auth.currentUser.uid).set({
          phoneNumber,
          displayName,
          admin,
          email,
        });
        // if (location.hostname !== "localhost") {
        await auth.currentUser.sendEmailVerification();
        // }
        await dispatch("fetchUserProfile");
      } catch (error) {
        throw new Error(error);
      }
    },
    async logOut({ commit }) {
      try {
        commit("SET_USER", null);
        commit("SET_ADMIN", false);
        commit("SET_AUTH", false);
        router.push({ name: "SignIn" });
        await auth.signOut();
      } catch (err) {
        throw new Error(err);
      }
    },
    async updateUser(_, data) {
      try {
        const updateUserInfo = functions.httpsCallable("updateUserInfo");
        const userRecord = await updateUserInfo({
          phoneNumber: data?.phoneNumber,
          email: data?.email,
          displayName: data?.displayName,
        });
        await userCollections.doc(auth.currentUser.uid).update({
          phoneNumber: data?.phoneNumber,
          email: data?.email,
          displayName: data?.displayName,
        });
        return userRecord;
      } catch (err) {
        throw new Error(err);
      }
    },
    async fetchUserProfile({ commit }) {
      // if (!auth?.currentUser) return;
      try {
        await userCollections.doc(auth.currentUser.uid).onSnapshot((doc) => {
          const data = doc.data();
          if (data) {
            data.email = auth.currentUser.email;
            commit("SET_USER", data);
            commit("SET_AUTH", true);
            commit("SET_ADMIN", data?.admin);
          }
        });
      } catch (err) {
        throw new Error(err);
      }
    },
    async deleteUser({ dispatch }, redirect = true) {
      if (!auth.currentUser) return router.push({ name: "SignIn" });
      try {
        const uid = auth.currentUser.uid;
        await userCollections.doc(uid).delete();
        await auth.currentUser.delete();
        router.push({ name: "SignIn" });
        dispatch("logOut");
        if (redirect) router.push({ name: "SignUp" });
      } catch (err) {
        throw new Error(err);
      }
    },
    async makeUserAdmin() {
      try {
        const makeAdmin = functions.httpsCallable("makeUserAdmin");
        const response = await makeAdmin();
        return response;
      } catch (err) {
        throw new Error(err);
      }
    },
    async createNewAdmin(_, signUpParams) {
      try {
        const createNewAdmin = functions.httpsCallable("createNewAdmin");
        const response = await createNewAdmin(signUpParams);
        return response;
      } catch (err) {
        throw new Error(err);
      }
    },
    async deleteMember(_, idToDelete) {
      try {
        const response = await userCollections.doc(idToDelete).delete();
        return response;
      } catch (err) {
        throw new Error(err);
      }
    },
    async uploadFile(_, { path, fileName, file }) {
      if (!fileName) fileName = file?.name;
      let storageRef = storage.ref();
      var uploadTask = storageRef.child(`${path}/${fileName}`).put(file);

      const promise = await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            throw new Error(error);
          },
          () => {
            storageRef
              .child(`${path}/${fileName}`)
              .getDownloadURL()
              .then((downloadURL) => {
                resolve(downloadURL);
              })
              .catch((err) => reject(err));
          }
        );
      });

      return await promise;
    },
    async getSettings({ commit }) {
      try {
        await preferencesCollections.doc("zSGrJDCeQrmF4HYFWOao").onSnapshot(
          (querySnapshot) => {
            let preferences = querySnapshot.data();
            preferences.id = querySnapshot.id;
            commit("SET_SETTINGS", preferences);
          },
          function (error) {
            throw error;
          }
        );
      } catch (err) {
        throw new Error(err);
      }
    },
    async getMembers({ commit }) {
      try {
        await userCollections.where("admin", "==", false).onSnapshot(
          (querySnapshot) => {
            if (querySnapshot.empty) return;
            let member = querySnapshot.docChanges()[0].doc.data();
            member.id = querySnapshot.docChanges()[0].doc.id;
            if (querySnapshot.docChanges()[0].type == "modified") {
              commit("MANAGE_MEMBERS", { action: "modified", member });
            } else if (querySnapshot.docChanges()[0].type == "removed") {
              commit("MANAGE_MEMBERS", { action: "removed", member });
            } else {
              const members = [];
              querySnapshot.docs.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                members.push(data);
              });
              commit("SET_MEMBERS", members);
            }
          },
          function (error) {
            throw error;
          }
        );
      } catch (err) {
        throw new Error(err);
      }
    },
  },
  modules: {},
  getters: {
    plays(state) {
      return state.plays;
    },
    members(state) {
      return state.members;
    },
    user(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuth;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isApproved: (state, getters) => (playId) => {
      return (
        getters.isAdmin ||
        getters?.user?.all_access ||
        (getters.isAuthenticated &&
          getters.user?.permissions?.includes?.(playId))
      );
    },
    settings(state) {
      return state.settings;
    },
    query(state) {
      return state.query;
    },
  },
});
