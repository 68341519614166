import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Scripts from "@/views/Scripts.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/scripts",
    name: "Scripts",
    component: Scripts,
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/Search.vue"),
  },
  {
    path: "/privacy",
    name: "NDA",
    component: () => import("@/views/NDA.vue"),
  },
  {
    path: "/bespoke",
    name: "Bespoke",
    component: () => import("@/views/Bespoke.vue"),
  },
  {
    path: "/scripts/:filter",
    name: "Genre",
    props: true,
    component: () => import("@/views/Genre.vue"),
  },
  {
    path: "/profile",
    // name: "Profile",
    component: () => import("@/layouts/Profile"),
    meta: {
      needsAuth: true,
    },
    children: [
      {
        path: "",
        name: "Profile",
        meta: {
          needsAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "SignIn" */ "@/views/Purchase.vue"),
      },
    ],
  },
  {
    path: "/admin",
    // name: "Profile",
    component: () => import("@/layouts/Admin"),
    meta: {
      needsAuth: true,
    },
    children: [
      {
        path: "",
        name: "ManagePlays",
        meta: {
          needsAdmin: true,
          needsAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AdminManagePlays" */ "@/views/Admin/ManagePlays.vue"
          ),
      },
      {
        path: "members",
        name: "ViewMembers",
        meta: {
          needsAdmin: true,
          needsAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AdminViewMembers" */ "@/views/Admin/ViewMembers.vue"
          ),
      },
      {
        path: ":user/purchases",
        name: "UserPurchases",
        meta: {
          needsAdmin: true,
          needsAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AdminUsersPurchases" */ "@/views/Admin/ViewMembers.vue"
          ),
      },
      {
        path: "play/:action/:id?",
        name: "UploadPlay",
        meta: {
          needsAdmin: true,
          needsAuth: true,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "AdminUploadPlay" */ "@/views/Admin/UploadPlay.vue"
          ),
      },
      {
        path: "create-admin",
        name: "CreateAdmin",
        meta: {
          needsAdmin: true,
          needsAuth: true,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "AdminCreateAdmin" */ "@/views/Admin/CreateAdmin.vue"
          ),
      },
      {
        path: "site-options",
        name: "SiteOptions",
        meta: {
          needsAdmin: true,
          needsAuth: true,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "AdminSiteOptions" */ "@/views/Admin/SiteOptions.vue"
          ),
      },
    ],
  },
  {
    path: "/auth",
    component: () => import("@/layouts/Auth"),
    redirect: { name: "SignIn" },
    meta: {
      hideNavbar: true,
    },
    children: [
      {
        path: "sign-in",
        name: "SignIn",
        meta: {
          hideNavbar: true,
        },
        component: () =>
          import(/* webpackChunkName: "SignIn" */ "@/views/Auth/SignIn"),
      },
      {
        path: "sign-up",
        name: "SignUp",
        meta: {
          hideNavbar: true,
        },
        component: () =>
          import(/* webpackChunkName: "SignUp" */ "@/views/Auth/SignUp"),
      },
      {
        path: "reset-password",
        name: "ForgotPassword",
        meta: {
          hideNavbar: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "ForgotPassword" */ "@/views/Auth/ForgotPassword"
          ),
      },
    ],
  },
  {
    path: "/play/:playId",
    name: "PDF",
    component: () => import("@/views/PDFReader.vue"),
    props: true,
    meta: {
      needsAuth: true,
    },
  },
  {
    path: "*",
    redirect: { name: "Scripts" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(async (to, from, next) => {
// if (to.meta.needsAuth || to.meta.needsAdmin)
//   await store.dispatch("fetchUserProfile");
// next();
// const getters = await store.getters;
// if (to.meta.needsAdmin && !getters.isAdmin) {
//   return next({ name: "Profile" });
// }
// if (to.meta.needsAuth && !getters.isAuthenticated) {
//   return next({ name: "Profile" });
// }
// next();
// });

export default router;
