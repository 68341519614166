<template>
  <div class="member">
    <div class="member-info">
      <h1 class="name">
        {{ user.displayName }}
        <span role="button" @click="deleteMember(user.id)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 24 24"
            fill="#C6C6C6"
          >
            <path d="M3 3H21V5H3z" />
            <path
              d="M16.1,22H7.9c-1,0-1.9-0.7-2-1.7L4,4.1l2-0.2L7.9,20l8.2,0L18,3.9l2,0.2l-1.9,16.1C18,21.3,17.1,22,16.1,22z"
            />
            <path
              d="M5,4l1.9,16.1c0.1,0.5,0.5,0.9,1,0.9h8.2 c0.5,0,0.9-0.4,1-0.9L19,4H5z"
              opacity=".3"
            />
            <path d="M15 3L15 4 9 4 9 3 10 2 14 2z" /></svg
        ></span>
      </h1>
      <p class="email">
        {{ user.email }}
      </p>
      <p class="tel">
        {{ user.phoneNumber }}
      </p>
    </div>

    <div class="permissions">
      <p>Give access to Plays</p>

      <select name="" id="" @change="updatePermissions($event.target.value)">
        <option>Select Play</option>
        <option v-for="play in filteredPlays" :key="play.id" :value="play.id">
          {{ play.name }}
        </option>
      </select>

      <div v-if="permittedPlays.length > 0" class="permissions-grid">
        <button v-for="play in permittedPlays" :key="play.id" class="item">
          {{ play.name }}
          <span role="button" @click="removePermission(play.id)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 24 24"
              fill="#C6C6C6"
            >
              <path d="M3 3H21V5H3z" />
              <path
                d="M16.1,22H7.9c-1,0-1.9-0.7-2-1.7L4,4.1l2-0.2L7.9,20l8.2,0L18,3.9l2,0.2l-1.9,16.1C18,21.3,17.1,22,16.1,22z"
              />
              <path
                d="M5,4l1.9,16.1c0.1,0.5,0.5,0.9,1,0.9h8.2 c0.5,0,0.9-0.4,1-0.9L19,4H5z"
                opacity=".3"
              />
              <path d="M15 3L15 4 9 4 9 3 10 2 14 2z" /></svg
          ></span>
        </button>
        <div class="spacer" style="padding-right: 20px"></div>
      </div>

      <div class="all-access">
        <input v-model="all_access" type="checkbox" name="" id="" required />
        <p>Grant user access to all plays</p>
      </div>
    </div>

    <div v-if="purchases.length > 0" class="purchases">
      <p>Recent Purchases</p>
      <div class="purchase-grid">
        <div v-for="purchase in purchases" :key="purchase.id" class="purchase">
          <h3>{{ purchase.name }}</h3>
          <p>{{ purchase.overview }}</p>
        </div>
        <div class="spacer" style="padding-right: 20px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  scriptCollections,
  userCollections,
  firebase,
} from "@/firebase-config";
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      purchases: [],
      all_access: this.user.all_access || false,
    };
  },
  computed: {
    ...mapGetters(["plays"]),
    filteredPlays() {
      return this.$store.getters.plays.filter(
        ({ id }) => !this.user.permissions?.includes?.(id)
      );
    },
    permittedPlays() {
      return this.$store.getters.plays.filter(({ id }) =>
        this.user.permissions?.includes?.(id)
      );
    },
  },
  watch: {
    async all_access(value) {
      try {
        await userCollections.doc(this.user.id).update({
          all_access: value,
        });
        if (value) {
          await userCollections.doc(this.user.id).update({
            requests: firebase.firestore.FieldValue.delete(),
          });
        }
      } catch (err) {
        throw new Error(err);
      }
    },
  },
  methods: {
    ...mapActions(["deleteMember"]),
    async getPurchases() {
      try {
        const scripts = await scriptCollections
          .where("owner", "==", this.user.id)
          .limit(10)
          .get();
        scripts.forEach((doc) => {
          const data = {
            id: doc.id,
            ...doc.data(),
          };
          this.purchases.push(data);
        });
      } catch (err) {
        throw new Error(err);
      }
    },
    async updatePermissions(playId) {
      try {
        await userCollections.doc(this.user.id).update({
          permissions: firebase.firestore.FieldValue.arrayUnion(playId),
        });
        await userCollections.doc(this.user.id).update({
          requests: firebase.firestore.FieldValue.arrayRemove(playId),
        });
      } catch (err) {
        throw new Error(err);
      }
    },
    async removePermission(playId) {
      try {
        await userCollections.doc(this.user.id).update({
          permissions: firebase.firestore.FieldValue.arrayRemove(playId),
        });
      } catch (err) {
        throw new Error(err);
      }
    },
  },
  created() {
    this.getPurchases();
  },
};
</script>

<style lang="scss" scoped>
.member {
  /* min-height: 300px; */
  padding: 20px 0;
  border-radius: 10px;
  /* border: 1px solid $dark-grey; */
  background: #23262b;

  .member-info {
    padding: 0 20px;

    .name {
      color: $light-grey;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      span {
        margin-left: 20px;

        svg {
          width: 25px;
          fill: $dark-red;

          &:hover {
            fill: $dark-grey;
          }
        }
      }
    }

    p {
      margin-top: 5px;
      font-size: 1.3rem;
      color: $dark-grey;
    }
  }

  .purchases {
    margin-top: 20px;
    padding-left: 20px;

    .purchase-grid {
      display: flex;
      overflow-x: auto;
      scrollbar-width: none;
      margin-top: 5px;
      margin-bottom: 15px;
      /* margin: 5px -5px 0; */

      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        display: none;
      }

      .purchase {
        margin: 0 2.5px;
        background: black;
        /* height: 150px; */
        /* padding-right: 40%; */
        padding: 20px;
        border-radius: 10px;
      }
    }

    a {
      font-size: 1.2rem;

      &:hover {
        color: $dark-grey;
      }
    }
  }

  .permissions {
    padding: 20px;

    select {
      height: 50px;
      background: black;
      margin-top: 5px;
      width: 100%;
      border: none;
      border-radius: 10px;
      padding: 0 15px;
      color: $light-grey;

      &:not(:valid) {
        border-bottom: 3px solid $dark-red;
      }
    }

    &-grid {
      display: flex;
      overflow-x: auto;
      scrollbar-width: none;
      margin-top: 5px;
      flex-wrap: wrap;
      /* margin: 5px -5px 0; */

      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        display: none;
      }

      .item {
        margin: 0 2.5px;
        background: turquoise;
        color: black;
        padding: 10px 15px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        margin: 4px;

        svg {
          width: 20px;
          fill: $red;
          margin-left: -20px;
          opacity: 0;
          transition: 0.2s all ease;
        }

        &:hover {
          font-weight: bold;
          svg {
            opacity: 1;
            margin-left: 10px;
          }
        }
      }
    }

    .all-access {
      display: flex;
      align-items: center;
      margin-top: 15px;
      font-size: 1.2rem;

      input {
        margin-right: 20px;
        transform: scale(1.5);
        transform-origin: left center;
      }
    }
  }
}
</style>
