<template>
  <div v-if="play" class="wrapper auth">
    <vue-page-transition name="flip-y">
      <div v-if="open && !openForm" class="popup">
        <h3>Play Options</h3>
        <ul class="options">
          <li class="option">
            <router-link tag="button" to="/scripts" class="home"
              >Go Home</router-link
            >
          </li>
          <li class="option" @click="openFormHandler('Exclusive')">
            <button class="exclusive">Make Exclusive</button>
          </li>
          <li class="option" @click="openFormHandler('Contact')">
            <button class="contact">Contact Us</button>
          </li>
        </ul>
      </div>
      <form v-if="openForm" class="auth-form" @submit.prevent="submitForm">
        <div class="header">
          <h3 class="title">{{ formTitle }}</h3>
          <svg
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 30 30"
            fill="#C6C6C6"
            @click="(openForm = false), (open = true)"
          >
            <path
              d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16.414,15 c0,0,3.139,3.139,3.293,3.293c0.391,0.391,0.391,1.024,0,1.414c-0.391,0.391-1.024,0.391-1.414,0C18.139,19.554,15,16.414,15,16.414 s-3.139,3.139-3.293,3.293c-0.391,0.391-1.024,0.391-1.414,0c-0.391-0.391-0.391-1.024,0-1.414C10.446,18.139,13.586,15,13.586,15 s-3.139-3.139-3.293-3.293c-0.391-0.391-0.391-1.024,0-1.414c0.391-0.391,1.024-0.391,1.414,0C11.861,10.446,15,13.586,15,13.586 s3.139-3.139,3.293-3.293c0.391-0.391,1.024-0.391,1.414,0c0.391,0.391,0.391,1.024,0,1.414C19.554,11.861,16.414,15,16.414,15z"
              fill="#C6C6C6"
            />
          </svg>
        </div>
        <fieldset class="body" :disabled="loading">
          <div
            v-if="formTitle.toLowerCase() === 'exclusive'"
            class="input-group"
          >
            <select
              v-model="form.duration"
              placeholder=" "
              name="duration"
              id="duration"
            >
              <option v-for="n in 3" :key="n" :value="`${n * 2} Weeks`">
                {{ n * 2 }} Weeks
              </option>
            </select>
            <label for="duration">Reservation Duration</label>
          </div>
          <div class="input-group">
            <textarea
              type="text"
              v-model="form.description"
              placeholder=" "
              name="description"
              rows="3"
              id="description"
            />
            <label for="description">Description</label>
          </div>
          <input type="hidden" name="user_name" :value="user.displayName" />
          <button :class="{ loading }" type="submit">
            <template v-if="!loading">Submit Form</template>
          </button>
        </fieldset>
      </form>
    </vue-page-transition>
    <button class="main" @click="handleOpen" :class="{ open }">
      <svg
        v-if="!open"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          fill="#1565C0"
          d="M20,36h16l6,6V20c0-2.209-1.791-4-4-4H20c-2.209,0-4,1.791-4,4v12C16,34.209,17.791,36,20,36"
        />
        <path
          fill="#2196F3"
          d="M29,26H12l-6,6V10c0-2.209,1.791-4,4-4h19c2.209,0,4,1.791,4,4v12C33,24.209,31.209,26,29,26"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 50 50"
        fill="#C6C6C6"
      >
        <path
          d="M9.15625 6.3125L6.3125 9.15625L22.15625 25L6.21875 40.96875L9.03125 43.78125L25 27.84375L40.9375 43.78125L43.78125 40.9375L27.84375 25L43.6875 9.15625L40.84375 6.3125L25 22.15625Z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  props: {
    play: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      openForm: false,
      formTitle: "Exclusive",
      loading: false,
      error: "",
      form: {
        description: "",
        duration: "2 Weeks",
      },
    };
  },
  watch: {
    open(newValue) {
      if (!newValue) {
        this.openForm = false;
        this.formTitle = "";
      }
    },
  },
  methods: {
    handleOpen() {
      this.open = !this.open;
    },
    openFormHandler(action) {
      this.openForm = true;
      this.formTitle = action;
    },
    async submitForm() {
      this.loading = true;
      this.error = "";
      try {
        const data = {
          User: this.user.displayName,
          Email: this.user.email,
          Phone_Number: this.user.phoneNumber,
          Description: this.form.description,
          Duration: this.form.duration,
          Play: this.play.name,
          Request_Type: this.formTitle,
        };
        await axios.post("https://formspree.io/f/myyleokz", {
          data,
        });
        this.form = {
          duration: "",
          description: "",
        };
        this.open = false;
        alert("form submitted");
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  z-index: 100;
}

.popup {
  background: white;
  position: absolute;
  min-width: 200px;
  border-radius: 8px;
  padding: 14px 20px;
  border: 1px solid #dfe3e6;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .options {
    list-style: none;

    .option {
      margin: 10px 0;

      button {
        font-weight: bold;
        padding: 12px 18px;
        border-radius: 20px;
        text-align: center;
        width: 100%;
        transition: 0.2s all ease;

        &.exclusive {
          background: black;
          color: white;
          border: 1px solid black;

          &:hover {
            background: turquoise;
            color: black;
          }
        }

        &.contact {
          background: transparent;
          border: 1px solid black;

          &:hover {
            background: turquoise;
            color: black;
          }
        }

        &.home {
          background: $red;
          color: white;

          &:hover {
            background: darken($color: $red, $amount: 14);
          }
        }
      }
    }
  }
}

.main {
  border: 1px solid #dfe3e6;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(to bottom, white 50%, black 50%);
  background-position: top;
  background-size: 100% 200%;
  outline: none;
  position: absolute;
  display: grid;
  place-items: center;
  transition: 0.2s all cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &:hover {
    background-position: bottom;

    &.open {
      svg {
        fill: white;
      }
    }
  }

  svg {
    width: 70%;
  }

  &.open {
    svg {
      width: 40%;
      fill: $main-bg;
    }
  }
}

form {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background: $main-bg;
  width: Min(350px, 100vw);
  position: absolute;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.5rem;
      font-weight: normal;
      color: white;
      text-transform: capitalize;
    }

    svg {
      height: 20px;
    }
  }

  fieldset.body {
    margin-top: 20px;

    .input-group {
      select {
        width: 100%;
        padding: 16px;
        padding-top: 24px;
        background: #202125;
        outline: none;
        border: none;
        height: 100%;
        border-radius: 10px;
        color: white;
        cursor: pointer;
      }

      textarea {
        height: 80px;
        padding-top: 25px;
        line-height: 1.2;
        font-size: 1.4rem;
      }
    }
  }
}

form,
.popup {
  bottom: calc(100% + 10px);
  transform: translateX(-40%);

  @media (min-width: 764px) {
    right: -50px;
    transform: unset;
  }
}
</style>
