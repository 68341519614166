import Vue from "vue";

Vue.filter("convertToThousand", (labelValue) => {
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Number(labelValue) / 1.0e9).toFixed(1) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Number(labelValue) / 1.0e6).toFixed(1) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Number(labelValue) / 1.0e3).toFixed(1) + "K"
    : Number(labelValue).toFixed(1);
});
