<template>
  <div id="home">
    <img :src="LogoWhite" alt="logo" />
    <div class="dictionary">
      <h1 class="spelling">narrative</h1>
      <h3 class="part-of-speech">noun</h3>
      <p class="definition">
        <em>a chronological narrative of Stark's life: </em
        ><b
          >ACCOUNT, story, tale, chronicle, history, description, record,
          portrayal, sketch, portrait, statement, report, rehearsal, recital,
          rendering.</b
        >
      </p>
    </div>
    <router-link :to="{ name: 'Scripts' }">Explore the Narrative </router-link>
  </div>
</template>

<script>
import { LogoWhite } from "@/assets";
export default {
  data() {
    return {
      LogoWhite,
    };
  },
  // beforeRouteLeave(to, from, next) {
  //   localStorage.setItem("visited", true);
  //   next();
  // },
  // beforeRouteEnter(to, from, next) {
  //   if (localStorage.getItem("visited")) next({ name: "Scripts" });
  //   else next();
  // },
};
</script>

<style lang="scss" scoped>
#home {
  /* width: 100%; */
  max-width: 600px;
  height: 100vh;
  display: grid;
  grid-gap: 30px;
  place-items: center;
  padding: 4vw;
  margin: 0 auto;

  img {
    max-width: 100%;
    max-height: 200px;
  }

  .dictionary {
    color: $light-grey;

    .spelling {
      font-size: 4.8rem;
      font-weight: bold;
    }

    .part-of-speech {
      font-size: 3.125rem;
      font-weight: 500;
    }

    .definition {
      font-size: 1.6rem;
    }
  }

  a {
    color: $light-grey;
    font-size: 1.4rem;

    &:hover {
      color: $dark-grey;
    }
  }
}
</style>
