<template>
  <main>
    <Promoted />
    <div id="scripts">
      <VueSlickCarousel v-if="loading" v-bind="carouselSettings" key="2">
        <div v-for="n in 5" :key="n" class="wrapper">
          <LoadingCard />
        </div>
      </VueSlickCarousel>
      <NoMatch
        v-else-if="plays.length === 0"
        :showButton="false"
        text="There are currently no available scripts to view.<br>Please check again later for new updates"
      />
      <template v-else>
        <div
          v-if="isMobile || (plays.length > 0 && plays.length < 4)"
          class="flex"
        >
          <div v-for="play in plays" :key="play.id" class="wrapper">
            <Play :play="play" />
          </div>
        </div>
        <VueSlickCarousel
          v-else
          v-bind="carouselSettings"
          ref="carousel"
          key="1"
          @mouseover.native="toggleCursors(true)"
          @mouseleave.native="toggleCursors(false)"
        >
          <div v-for="play in plays" :key="play.id" class="wrapper">
            <Play :play="play" />
          </div>
          <div v-if="plays.length === settings.playsLimit" class="wrapper last">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 32 32"
              title="See All Plays"
              aria-label="See All Plays"
            >
              <path
                d="M16 3C8.832031 3 3 8.832031 3 16C3 23.167969 8.832031 29 16 29C23.167969 29 29 23.167969 29 16C29 8.832031 23.167969 3 16 3 Z M 16 5C22.085938 5 27 9.914063 27 16C27 22.085938 22.085938 27 16 27C9.914063 27 5 22.085938 5 16C5 9.914063 9.914063 5 16 5 Z M 16.71875 9.59375L15.28125 11L19.28125 15L9 15L9 17L19.28125 17L15.28125 21L16.71875 22.40625L23.125 16Z"
              />
            </svg>
          </div>
        </VueSlickCarousel>
      </template>
    </div>
  </main>
</template>

<script>
import { Play, LoadingCard, NoMatch, Promoted } from "@/components";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { detectMobile } from "@/utils/mobile-detection";

import { mapGetters } from "vuex";

export default {
  name: "Script",
  components: {
    VueSlickCarousel,
    Play,
    LoadingCard,
    NoMatch,
    Promoted,
  },
  data() {
    return {
      carouselSettings: {
        dots: true,
        speed: 500,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        variableWidth: true,
        dotsClass: "indicator",
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              initialSlide: 0,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              infinite: true,
              slidesToScroll: 1,
            },
          },
        ],
      },
      loading: true,
    };
  },
  watch: {
    plays() {
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["plays", "settings"]),
    isMobile() {
      return detectMobile();
    },
  },
  methods: {
    toggleCursors() {
      // console.log(state);
      // return;
      // eslint-disable-next-line no-unreachable
      // const cursors = this.$el.querySelectorAll(".slick-arrow");
      // cursors.forEach((cursor) => (cursor.style.opacity = state ? 1 : 0));
    },
  },
  mounted() {
    if (this.plays) this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
$card-height: 440.9px;

#scripts {
  overflow: hidden;
  display: flex;
  padding: 50px 0;
  flex-flow: column wrap;
  justify-content: center;
  /* margin-top: -30vh; */

  @media (min-width: 764px) {
    /* margin-top: -38vh; */
  }

  @media (min-width: 1024px) {
    /* margin-top: -25vh; */
    padding: 2vw 0;
    min-height: calc(100vh - 70px);
  }

  /deep/.slick-slider {
    display: flex;
    flex-direction: column;

    .slick-arrow {
      z-index: 50;
      width: Max(4vw, 40px);
      height: 100%;
      background: linear-gradient(
        to bottom,
        transparent,
        rgba(black, 0.9),
        rgba(black, 0.9),
        transparent
      );
      transition: 0.3s all ease;
      opacity: 1;
      display: grid !important;
      place-items: center;
      grid-template-rows: 1fr 0;

      &:hover::before {
        transform: scale(1.5);
      }

      &::before {
        all: unset;
        width: 30px;
        transition: 0.3s all ease;
      }

      &.slick-prev {
        left: 0;

        &::before {
          content: url(~@/assets/left.svg);
        }
      }

      &.slick-next {
        left: calc(100vw - Max(4vw, 40px));

        &::before {
          content: url(~@/assets/right.svg);
        }
      }

      &.slick-disabled {
        opacity: 0 !important;
      }
    }

    .slick-list {
      order: 2;
      padding-left: 4vw;
    }

    .slick-slide {
      width: fit-content !important;
      padding: 0 2.5px;
      transition: 0.1s padding ease;
    }

    .indicator {
      display: grid !important;
      width: 300px;
      grid-template-columns: repeat(auto-fill, minmax(10px, 1fr));
      justify-content: flex-end;
      grid-gap: 1px;
      /* margin-left: calc(100vw - 5vw); */
      margin-left: 4vw;
      list-style: none;

      li {
        button {
          font-size: 0;
          all: unset;
          cursor: pointer;
          height: 2px;
          background: #e5e5e5;
          opacity: 0.1;
        }

        &.slick-active {
          button {
            opacity: 1;
          }
        }
      }
    }
  }

  .wrapper {
    display: unset !important;
  }

  .wrapper.last {
    display: grid !important;
    place-items: center;
    height: $card-height;
    padding: 20px;

    svg {
      fill: #c6c6c6;
      opacity: 0.5;
      cursor: pointer;
      width: 5vw;
      transition: 0.3s all ease;

      &:hover {
        opacity: 1;
        transform: translateX(20px);
      }
    }

    @media (min-width: 768px) {
      $card-height: 460.9px;
      height: $card-height;
    }
  }

  .flex {
    display: flex;
    width: 100vw;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      display: none;
    }

    .wrapper {
      padding-right: 2.5px;

      &:first-of-type {
        margin-left: 4vw;
      }

      &:last-of-type {
        padding-right: 4vw;
      }
    }
  }
}
</style>
