<template>
  <div>
    <div class="profile-title">
      <span> Admins </span>
    </div>
    <div id="admin-grid">
      <template v-if="loading">
        <LoadingCard v-for="n in 3" :key="n" width="100%" height="100px" />
      </template>
      <template v-if="!loading && admins.length > 0">
        <AdminMember v-for="admin in admins" :key="admin.id" :user="admin" />
      </template>
      <h1 v-else>No other admins</h1>
    </div>
  </div>
</template>

<script>
import { AdminMember, LoadingCard } from "@/components";
import { userCollections, auth } from "@/firebase-config";
export default {
  components: {
    AdminMember,
    LoadingCard,
  },
  data() {
    return {
      loading: true,
      admins: [],
    };
  },
  methods: {
    async fetchAdmins() {
      try {
        this.loading = true;
        await userCollections.where("admin", "==", true).onSnapshot(
          (querySnapshot) => {
            let admin = querySnapshot.docChanges()[0].doc.data();
            admin.id = querySnapshot.docChanges()[0].doc.id;
            if (querySnapshot.docChanges()[0].type == "modified") {
              this.modifyAdmin(admin.id, admin);
            } else if (querySnapshot.docChanges()[0].type == "removed") {
              this.removeAdmin(admin.id);
            } else {
              querySnapshot.docs.forEach((doc) => {
                let data = doc.data();
                if (doc.id !== auth.currentUser.uid) {
                  data.id = doc.id;
                  this.admins.push(data);
                }
              });
            }
          },
          function (error) {
            throw error;
          }
        );
      } catch (err) {
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
    removeAdmin(id) {
      const adminIndexToRemove = this.admins.findIndex(
        (admin) => admin.id === id
      );
      if (adminIndexToRemove > -1) this.admins.splice(adminIndexToRemove, 1);
    },
    modifyAdmin(id, newData) {
      const adminIndexToModify = this.admins.findIndex(
        (admin) => admin.id === id
      );
      if (adminIndexToModify > -1)
        this.$set(this.admin, adminIndexToModify, newData);
    },
  },
  mounted() {
    this.fetchAdmins();
  },
};
</script>

<style lang="scss" scoped>
#admin-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}
</style>
