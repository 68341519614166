import firebase from "firebase/app";

import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";

// firebase init goes here
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtZJfiMyaVtTMX111mOj8zn7noPiWsptQ",
  authDomain: "thenarrative-e16c3.firebaseapp.com",
  databaseURL:
    "https://thenarrative-e16c3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "thenarrative-e16c3",
  storageBucket: "thenarrative-e16c3.appspot.com",
  messagingSenderId: "91437805850",
  appId: "1:91437805850:web:0f4972ebdb819380327ca3",
  measurementId: "G-KRDE62KQ7L",
};

const app = firebase.initializeApp(firebaseConfig);

// firebase utils
const db = app.firestore();
const storage = app.storage();
const auth = app.auth();
const functions = app.functions("europe-west1");

// if (location.hostname === "localhost") {
//   // Point to the RTDB emulator running on localhost.
//   functions.useEmulator("localhost", 5001);
//   auth.useEmulator("http://localhost:9099");
//   db.useEmulator("localhost", 9001);
// }

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch((err) => {
    console.log(err.message);
  });

const userCollections = db.collection("users");
const scriptCollections = db.collection("scripts");
const preferencesCollections = db.collection("preferences");

const currentUser = auth.currentUser;

export {
  userCollections,
  scriptCollections,
  preferencesCollections,
  auth,
  functions,
  storage,
  currentUser,
  firebase,
};
