<template>
  <nav :class="{ dark: isProfile }">
    <div class="logo">
      <router-link to="/scripts">
        <img alt="Vue logo" :src="LogoWhite" />
      </router-link>
    </div>
    <ul class="nav-list" :class="{ 'fit-width': isProfile }" @click="indicate">
      <li class="nav-item">
        <router-link to="/scripts">
          {{ isProfile ? "Home" : "All Scripts" }}
        </router-link>
      </li>
      <li v-for="item in navItems" :key="item" class="nav-item">
        <router-link
          :to="{
            name: 'Genre',
            params: { filter: item },
          }"
        >
          {{ item }}
        </router-link>
      </li>
      <!-- 
      <template v-if="!isProfile">
        <li v-for="item in navItems" :key="item" class="nav-item">
          <router-link
            :to="{
              name: 'Genre',
              params: { filter: item },
            }"
          >
            {{ item }}
          </router-link>
        </li>
        <template
          v-if="settings && settings.genres && settings.genres.length < 4"
        >
          <li
            v-for="genre in settings.genres.slice(0, 5)"
            :key="genre"
            class="nav-item"
          >
            <router-link
              :to="{
                name: 'Genre',
                params: { filter: genre },
              }"
            >
              {{ genre }}
            </router-link>
          </li>
        </template>
        <GenreDropdown class="nav-item" v-else />
      </template>
       -->
      <li class="nav-item">
        <router-link to="/bespoke"> Services </router-link>
      </li>
      <div class="indicator" :style="{ left, width }"></div>
    </ul>
    <SearchBar v-if="!isProfile" />
    <template>
      <router-link
        v-if="isAuthenticated && !isAdmin"
        :to="{ name: 'Profile' }"
        class="profile"
        :class="{ auto: isProfile }"
        aria-label="profile"
      >
        <div class="img">
          <img
            src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/366be133850498.56ba69ac36858.png"
            alt=""
          />
        </div>
      </router-link>
      <router-link
        v-else-if="isAdmin"
        :to="{ name: 'ManagePlays' }"
        class="profile"
        :class="{ auto: isProfile }"
        aria-label="profile"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 24 24"
        >
          <path
            d="M12 11c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4S14.2 11 12 11zM12 5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2S13.1 5 12 5zM22.9 19.8c0-.2.1-.5.1-.8s0-.5-.1-.8l.8-.6c.1-.1.1-.2.1-.3l-.9-1.6c-.1-.1-.2-.2-.3-.1l-1 .4c-.4-.3-.8-.6-1.3-.8l-.1-1c0-.1-.1-.2-.3-.2H18c-.1 0-.2.1-.3.2l-.1 1c-.5.2-.9.4-1.3.8l-.9-.4c-.1-.1-.3 0-.3.1l-.9 1.6c-.1.1 0 .3.1.3l.8.6c0 .2-.1.5-.1.8s0 .5.1.8l-.8.6c-.1.1-.1.2-.1.3l.9 1.6c.1.1.2.2.3.1l.9-.4c.4.3.8.6 1.3.8l.1 1c0 .1.1.2.3.2h1.9c.1 0 .2-.1.3-.2l.1-1c.5-.2.9-.4 1.3-.8l.9.4c.1.1.3 0 .3-.1l.9-1.6c.1-.1 0-.3-.1-.3L22.9 19.8zM19 20.7c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8C20.8 20 20 20.7 19 20.7z"
          />
          <path d="M12 4A3 3 0 1 0 12 10A3 3 0 1 0 12 4Z" opacity=".3" />
          <path
            d="M12,19H5v-0.5c0-0.9,3.7-2.5,7-2.5c0.2,0,0.4,0,0.7,0c0.3-0.7,0.8-1.3,1.3-1.9c-0.7-0.1-1.4-0.2-2-0.2c-3.1,0-9,1.5-9,4.5 V21h9.3C12.1,20.4,12,19.7,12,19z"
          />
          <path
            d="M12,19c0-1.5,0.5-2.9,1.3-4c-0.1,0-0.2,0-0.3,0c-8,0-9,3-9,3v2h8.1 C12,19.7,12,19.3,12,19z"
            opacity=".3"
          />
        </svg>
      </router-link>
      <router-link
        v-else
        :to="{ name: 'SignIn' }"
        class="profile text"
        :class="{ auto: isProfile }"
        aria-label="profile"
      >
        Sign In
      </router-link>
    </template>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { LogoWhite } from "@/assets";
import SearchBar from "@/components/SearchBar.vue";
// import GenreDropdown from "@/components/GenreDropdown.vue";

export default {
  components: {
    SearchBar,
    // GenreDropdown,
  },
  data() {
    return {
      navItems: ["TV", "Film"],
      left: 0,
      width: 0,
      LogoWhite,
    };
  },
  computed: {
    isProfile() {
      return (
        this.$route?.path?.includes?.("profile") ||
        this.$route?.path?.includes?.("admin")
      );
    },
    ...mapGetters(["settings", "isAuthenticated", "isAdmin"]),
  },
  methods: {
    indicate() {
      if (this.isProfile) return;
      const activeLink = this.$el?.querySelector(
        ".nav-item .router-link-exact-active"
      );
      const activeElement = activeLink?.parentElement;

      const rect = activeLink?.getClientRects()[0];
      this.left = activeElement?.offsetLeft + "px";
      this.width = rect?.width + "px";
    },
  },
  mounted() {
    this.indicate();
  },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  flex-wrap: wrap;
  background: linear-gradient(to bottom, black, transparent);
  min-height: 70px;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 4vw;
  align-items: center;
  transition: 0.5s background ease, padding ease;

  &.dark {
    background: #16171b;
    padding: 10px 4vw;
    transition: 0.5s background ease, padding ease;
    border-bottom: 1px solid black;
  }

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 100000;
  }

  .logo {
    height: 30px;
    margin-right: 25px;

    img {
      object-fit: contain;
      max-height: 100%;
    }
  }

  .nav-list {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    list-style: none;
    font-size: 1.4rem;
    order: 4;
    margin-top: 30px;
    scrollbar-width: none;
    position: relative;
    height: 30px;

    &.fit-width {
      order: unset;
      margin-top: 0;
      width: fit-content;

      .indicator {
        display: none;
      }
    }

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      display: none;
    }

    .nav-item {
      min-width: fit-content;
      animation: float-in 400ms ease 0s forwards;
      opacity: 0;

      @for $i from 2 through 6 {
        &:nth-of-type(#{$i}) {
          animation-delay: $i * 100ms;
        }
      }

      @keyframes float-in {
        from {
          transform: translateY(20px);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }

      a {
        text-decoration: none;
        color: $light-grey;
        transition: 0.3s all ease;

        &:hover,
        &:focus {
          color: $dark-grey;
        }

        &.router-link-exact-active {
          color: #fff;
          font-weight: 700;
        }
      }
    }

    .indicator {
      display: inline-block;
      height: 3px;
      width: 50px;
      background: white;
      position: absolute;
      left: 0;
      bottom: 0px;
      transition: 0.3s left ease-out, 0.3s width ease-out;
    }
  }

  /deep/ .search {
    margin-left: auto;
    position: relative;
    width: 100%;
    order: 4;
    margin-top: 20px;
  }

  .profile {
    /* margin-left: 15px; */
    margin-left: auto;
    order: 3;

    .img {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: bottom right;
        height: 100%;
        transition: 0.3s opacity ease;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    svg {
      fill: $light-grey;
      height: 32px;

      &:hover {
        fill: $dark-grey;
      }
    }

    &.auto {
      margin-left: auto;
    }
  }

  .profile.text {
    color: white;
    font-size: 1.4rem;
  }

  @media (min-width: 664px) {
    /deep/ .search {
      width: fit-content;
      order: unset;
      margin-top: unset;

      input {
        width: 400px;
      }
    }

    .profile {
      order: unset;
      margin-left: 15px;
    }
  }

  @media (min-width: 1024px) {
    padding: 10px 4vw;

    .nav-list {
      order: unset;
      margin-top: 0;
      width: fit-content;

      .indicator {
        display: none;
      }

      .nav-item {
        padding-right: 18px;
      }
    }

    /deep/ .search {
      input {
        width: 100%;
      }
    }
  }

  @media (min-width: 1150px) {
    /deep/ .search {
      input {
        width: 300px;
      }
    }
  }
}
</style>
