<template>
  <div class="dropdown" aria-haspopup="true" :aria-expanded="open">
    <p @click="open = !open" :class="{ owner }">
      {{ owner ? owner.displayName : "NA" }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 30 30"
      >
        <path
          d="M15,23c-0.256,0-0.512-0.098-0.707-0.293l-10-10c-0.286-0.286-0.372-0.716-0.217-1.09C4.23,11.243,4.596,11,5,11h20 c0.404,0,0.77,0.243,0.924,0.617c0.155,0.374,0.069,0.804-0.217,1.09l-10,10C15.512,22.902,15.256,23,15,23z"
          fill="#C6C6C6"
        />
      </svg>
    </p>
    <vue-page-transition name="zoom">
      <ul v-if="open" class="dropdown-list" @click="open = false">
        <li
          v-if="owner"
          class="dropdown-item"
          @click="$emit('updateOwner', null)"
        >
          No Owner
        </li>
        <li
          v-for="member in allowedMembers"
          :key="member.id"
          class="dropdown-item"
          @click="$emit('updateOwner', member.id)"
        >
          {{ member.displayName }} ({{ member.email }})
        </li>
      </ul>
    </vue-page-transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    memberId: {
      type: String,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapGetters(["members"]),
    owner() {
      return this.members.find((member) => member.id === this.memberId);
    },
    allowedMembers() {
      return this.members.filter((member) => member.id !== this.memberId);
    },
  },
  watch: {
    $route() {
      this.open = false;
    },
  },
  methods: {
    handleOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.open = false;
        this.activeInput = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    document.addEventListener("focus", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
    document.removeEventListener("focus", this.handleOutsideClick);
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  color: $light-grey;
  position: relative;
}

p {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 2rem;
  font-weight: bold;

  &.owner {
    font-size: 1.4rem;
    line-height: 1.2;
  }

  svg {
    height: 10px;
    margin-left: 15px;
  }
}

.dropdown-list {
  position: absolute;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  width: Min(250px, 70vw);
  list-style: none;
  background: black;
  display: inline-block;
  border-radius: 5px;
  top: 130%;
  z-index: 9999999999;

  .dropdown-item {
    padding: 15px;
    cursor: pointer;

    &:hover {
      background: turquoise;
      color: black;
    }
  }
}
</style>
