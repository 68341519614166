<template>
  <div class="admin-play">
    <div class="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
      >
        <path
          d="M19,20c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1V4c0-0.6,0.4-1,1-1h7.6L19,8.4V20z"
          opacity=".3"
        />
        <path d="M8 12H16V14H8zM8 16H16V18H8z" />
        <path
          d="M18,22H6c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h8l6,6v12C20,21.1,19.1,22,18,22z M6,4v16h12V8.8L13.2,4H6z"
        />
        <path d="M18.5 9L13 9 13 3.5z" />
      </svg>
    </div>
    <div class="title">
      <h2>{{ play.title || play.name }}</h2>
      <span class="badge">{{ play.media_type }}</span>
    </div>

    <div class="popularity">
      <div class="graph">
        <div class="doughnut">
          <svg height="56" width="56" xmlns="http://www.w3.org/2000/svg">
            <circle
              class="base"
              r="25"
              cy="28"
              cx="28"
              stroke-width="6"
              stroke="#333"
              fill="none"
            />
            <circle
              v-if="!play.owner"
              class="circle_animation"
              :class="popularityRating"
              r="25"
              cy="28"
              cx="28"
              stroke-width="6"
              stroke-linecap="round"
              :style="{ strokeDashoffset: 157 - (percentage / 100) * 157 }"
              fill="none"
            />
            <circle
              v-else
              class="circle_animation"
              :class="popularityRating"
              r="25"
              cy="28"
              cx="28"
              stroke-width="6"
              stroke-linecap="round"
              fill="none"
            />
          </svg>
          <span>{{ play.owner ? "NA" : parseInt(percentage) }}</span>
        </div>
        <p>
          Score <br />
          out of 100
        </p>
      </div>
    </div>

    <div class="views">
      <p>Views:</p>
      <h2 v-if="play.views" :title="play.views">
        {{ play.views | convertToThousand }}
      </h2>
      <h2 v-else :title="play.views">0</h2>
    </div>

    <div class="owner">
      <p>Owner:</p>
      <Dropdown :memberId="play.owner" @updateOwner="updateOwner" />
    </div>

    <div v-if="!play.owner" class="actions">
      <router-link
        :to="{
          name: 'UploadPlay',
          params: { action: 'edit', id: play.id, playToUpdate: play },
        }"
        role="button"
        class="edit"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 24 24"
        >
          <path
            d="M22.146,20.146l-2,2L15,17v-2h2L22.146,20.146z M23.854,21.854l-1-1l-2,2l1,1c0.195,0.195,0.512,0.195,0.707,0l1.293-1.293C24.049,22.365,24.049,22.049,23.854,21.854z"
          />
          <path
            d="M13 17.828L13 13 17.828 13 19 14.172 19 9 13 3 5 3 5 21 16.172 21z"
            opacity=".3"
          />
          <path
            d="M15.172,20H6V4h7.172L18,8.828v4.343l2,2V8l-6-6H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h11.172L15.172,20z"
          />
          <path d="M18.5 9L13 9 13 3.5z" />
        </svg>
      </router-link>
      <span role="button" class="delete" @click="deletePlay">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 24 24"
          fill="#C6C6C6"
        >
          <path d="M3 3H21V5H3z" />
          <path
            d="M16.1,22H7.9c-1,0-1.9-0.7-2-1.7L4,4.1l2-0.2L7.9,20l8.2,0L18,3.9l2,0.2l-1.9,16.1C18,21.3,17.1,22,16.1,22z"
          />
          <path
            d="M5,4l1.9,16.1c0.1,0.5,0.5,0.9,1,0.9h8.2 c0.5,0,0.9-0.4,1-0.9L19,4H5z"
            opacity=".3"
          />
          <path d="M15 3L15 4 9 4 9 3 10 2 14 2z" />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import { scriptCollections } from "@/firebase-config";
import Dropdown from "./Dropdown";

export default {
  components: {
    Dropdown,
  },
  props: {
    play: Object,
    maxViews: [Number, String],
  },
  computed: {
    percentage() {
      return (this.play.views / this.maxViews) * 100;
    },
    popularityRating() {
      if (this.play.owner) return "disabled";
      return this.percentage <= 35
        ? "bad"
        : this.percentage <= 69
        ? "okay"
        : "good";
    },
  },
  methods: {
    async updateOwner(newOwnerId) {
      try {
        await scriptCollections.doc(this.play.id).update({ owner: newOwnerId });
      } catch (err) {
        throw new Error(err);
      }
    },
    async deletePlay() {
      const x = confirm("Are you sure you want to delete: " + this.play.name);
      if (!x) return;
      try {
        await scriptCollections.doc(this.play.id).delete();
        scriptCollections;
      } catch (err) {
        alert("Something went wrong during the delete");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-play {
  padding: 30px 20px;
  background: $dashboard-card;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  transition: 0.6s all ease;

  @media (min-width: 500px) {
    .title {
      flex-basis: calc(100% - 50px);
    }

    .popularity {
      flex-basis: 30%;
      order: 3;
    }

    .views {
      flex-basis: 20%;
      order: 4;
    }

    .owner {
      flex-basis: 30%;
      order: 5;
    }
  }

  @media (min-width: 700px) {
    display: grid;
    grid-template-columns: 1.5fr 1.2fr 85px 1fr 30px;

    p {
      font-size: 1.2rem;
      line-height: 1;
    }

    div {
      order: unset !important;
      flex-basis: unset !important;
    }
  }

  @media (min-width: 849px) {
    grid-gap: 40px;
    grid-template-columns: 30px 2.4fr 2fr 1fr 1fr 30px;

    p {
      font-size: 1.3rem;
    }
  }

  .icon {
    width: 25px;
    fill: $light-grey;
    display: none;

    @media (min-width: 849px) {
      display: block;
    }
  }

  .title {
    h2 {
      color: $dark-grey;
      margin-bottom: 15px;
      max-width: 80%;
    }
    .badge {
      padding: 10px;
      border-radius: 50px;
      background: $input-color;
      text-transform: uppercase;
    }
  }

  .popularity {
    flex-basis: 40%;

    .graph {
      display: flex;
      align-items: center;

      .doughnut {
        margin-right: 15px;
        display: grid;
        place-items: center;
        font-weight: bold;
        font-size: 1.3rem;
        position: relative;

        svg {
          transform: rotate(-90deg);

          .good {
            stroke: #6fdb6f;
          }

          .bad {
            stroke: $red;
          }

          .okay {
            stroke: orange;
          }

          .disabled {
            stroke: $light-grey;
          }
        }

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .circle_animation {
          stroke-dasharray: 157;
          /* stroke-dashoffset: 157; */
          transition: 0.3s stroke-dashoffset ease-out;
        }
      }
    }
  }

  .views {
    h2 {
      font-size: 2.8rem;
      font-weight: 400;
      line-height: 1;
    }
  }

  .owner {
    h2 {
      line-height: 1;
    }
  }

  .actions {
    text-align: right;
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 10px;
    width: 30px;
    order: 2;

    span,
    a {
      svg {
        width: 20px;
        fill: $light-grey;
        transition: 0.3s all ease;

        &:hover {
          fill: $dark-grey;
        }
      }

      &.delete {
        svg {
          fill: $red;

          &:hover {
            fill: $dark-red;
          }
        }
      }
    }
  }
}
</style>
