<template>
  <div id="app">
    <Navbar v-if="showNavbar" />
    <vue-page-transition name="fade-in-up">
      <router-view />
    </vue-page-transition>
  </div>
</template>

<script>
import { Navbar } from "@/components";

export default {
  components: {
    Navbar,
  },
  computed: {
    showNavbar() {
      return !this.$route.meta.hideNavbar;
      // return true;
    },
  },
  async beforeMount() {
    if (this.$store.getters.isAdmin) await this.$store.dispatch("getMembers");
  },
};
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $main-bg;
  /* padding-top: 70px; */
  min-height: calc(100vh - 70px);
  /* scrollbar-width: none; */
}
</style>
