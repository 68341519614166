<template>
  <vue-page-transition name="fade">
    <div v-if="play" class="promoted">
      <div
        class="background"
        :style="{ backgroundImage: `url(${play.backdrop_path})` }"
      ></div>
      <div class="body">
        <h1 class="title">{{ play.name }}</h1>
        <p class="overview">{{ play.overview }}</p>
        <PlayButton :play="play" />
      </div>
    </div>
    <LoadingCard v-else width="100vw" height="100vh" />
  </vue-page-transition>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingCard from "@/components/Loading/LoadingCard";
import PlayButton from "./Button";

export default {
  components: {
    LoadingCard,
    PlayButton,
  },
  computed: {
    ...mapGetters(["plays", "settings"]),
    play() {
      return this.plays.find((play) => play.id === this.settings.promoted);
    },
  },
};
</script>

<style lang="scss" scoped>
.promoted {
  height: 100vh;
  position: relative;
  padding: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;
    mask-image: linear-gradient(to bottom, transparent, black);
    z-index: 0;
  }

  .body {
    position: relative;
    z-index: 1;
    max-width: 70%;
    text-align: center;

    .title {
      font-size: clamp(6ch, 10vw, 10ch);
      font-weight: 700;
      line-height: 1;
      color: yellow;
      text-align: center;

      @media screen and (max-width: 500px) {
        font-size: 4.2rem;
      }
    }

    .overview {
      font-size: 1.8rem;
      margin-top: 18px;
      color: white;
    }

    .scale {
      transform: scale(1.3);
      transform-origin: left center;
    }
  }

  @media (min-width: 1024px) {
    /* justify-content: flex-start; */
    align-items: flex-start;

    .body {
      max-width: 50%;
      text-align: unset;

      .title {
        text-align: right;
        text-align-last: right;
      }
    }
  }
}
</style>
