<template>
  <div class="dropdown" aria-haspopup="true" :aria-expanded="open">
    <div class="main-wrapper">
      <div class="genre-selector genres">
        <template v-if="selected.length > 0">
          <div
            v-for="(genre, index) in selected"
            :key="genre"
            class="genre"
            @click="removeGenre(index)"
          >
            {{ genre }}
          </div>
        </template>
        <p v-else>Play Genres</p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 30 30"
        role="button"
        @click="open = !open"
      >
        <path
          d="M15,23c-0.256,0-0.512-0.098-0.707-0.293l-10-10c-0.286-0.286-0.372-0.716-0.217-1.09C4.23,11.243,4.596,11,5,11h20 c0.404,0,0.77,0.243,0.924,0.617c0.155,0.374,0.069,0.804-0.217,1.09l-10,10C15.512,22.902,15.256,23,15,23z"
          fill="#C6C6C6"
        />
      </svg>
    </div>
    <vue-page-transition name="zoom">
      <ul v-if="open" class="dropdown-list">
        <li
          v-for="genre in genres"
          :key="genre"
          class="dropdown-item"
          @click="addGenre(genre)"
        >
          {{ genre }}
        </li>
      </ul>
    </vue-page-transition>
  </div>
</template>

<script>
export default {
  props: {
    genres: {
      type: Array,
      required: true,
    },
    value: Array,
  },
  data() {
    return {
      open: false,
      selected: this.value,
    };
  },
  methods: {
    addGenre(genre) {
      if (this.selected.includes(genre)) return;
      else this.selected.push(genre);
    },
    removeGenre(index) {
      this.selected.splice(index, 1);
    },
    handleOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.open = false;
      }
    },
  },
  watch: {
    selected(newValue) {
      this.$emit("input", newValue);
    },
    open(value) {
      if (value) document.addEventListener("click", this.handleOutsideClick);
      else document.removeEventListener("click", this.handleOutsideClick);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  max-height: 70px;
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 5px;
  background: #202125;
  padding: 10px;
  border-radius: 10px;

  svg {
    margin-top: 8px;
  }
}

.genre-selector {
  padding: 5px;
  position: relative;

  p {
    font-size: 1.6rem;
  }

  &.genres {
    width: 100%;
    height: 100%;
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    /* padding: 5px; */
    /* padding-right: 10px; */
    /* border-right: 2px solid black; */

    .genre {
      padding: 6px 10px;
      margin: 1px;
      background: black;
      border-radius: 6px;
      cursor: pointer;
      transition: 0.2s background ease;

      &:hover {
        background: $dark-red;
      }
    }

    label {
      color: #8c8c8c;
      font-size: 1.6rem;
      padding-left: 20px;
    }
  }
}

.dropdown {
  position: relative;
  margin-bottom: 15px;
}
.dropdown-list {
  position: absolute;
  left: 0;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  list-style: none;
  background: black;
  display: inline-block;
  border-radius: 5px;
  top: 100%;
  z-index: 2;

  .dropdown-item {
    padding: 15px;
    cursor: pointer;

    &:hover {
      background: turquoise;
      color: black;
    }
  }
}
</style>
