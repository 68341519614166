<template>
  <div :style="{ width, height }" class="loading-card"></div>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>

<style lang="scss" scoped>
$card-height: 440.9px;
$card-width: 270px;

.loading-card {
  background-color: #333;
  width: $card-width;
  height: $card-height;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  @media (min-width: 768px) {
    $card-height: 460.9px;
    $card-width: 348px;
    width: $card-width;
    height: $card-height;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";

    @media (prefers-reduced-motion: reduce) {
      background: none;
      animation: none;
    }
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
