<template>
  <div class="dropdown" aria-haspopup="true" :aria-expanded="open">
    <p @click="open = !open">
      Genres
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 30 30"
      >
        <path
          d="M15,23c-0.256,0-0.512-0.098-0.707-0.293l-10-10c-0.286-0.286-0.372-0.716-0.217-1.09C4.23,11.243,4.596,11,5,11h20 c0.404,0,0.77,0.243,0.924,0.617c0.155,0.374,0.069,0.804-0.217,1.09l-10,10C15.512,22.902,15.256,23,15,23z"
          fill="#C6C6C6"
        />
      </svg>
    </p>
    <vue-page-transition name="zoom">
      <ul v-if="open" class="dropdown-list">
        <router-link
          v-for="genre in settings.genres"
          :key="genre"
          :to="{ name: 'Genre', params: { filter: genre } }"
          class="dropdown-item"
          tag="li"
          >{{ genre }}</router-link
        >
      </ul>
    </vue-page-transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapGetters(["settings"]),
  },
  watch: {
    $route() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  color: $light-grey;
  position: relative;
  z-index: 9999999999;
}

p {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    height: 10px;
    margin-left: 15px;
  }
}

.dropdown-list {
  position: absolute;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  max-width: 300px;
  list-style: none;
  background: black;
  display: inline-block;
  border-radius: 5px;
  top: 150%;

  @media (min-width: 1024px) {
    right: 50%;
    transform: translateX(50%);
  }

  .dropdown-item {
    padding: 15px;
    cursor: pointer;

    &:hover {
      background: turquoise;
      color: black;
    }
  }
}
</style>
