import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import store from "./store";
import { auth } from "./firebase-config";
import VuePageTransition from "vue-page-transition";
import "@/assets/styles/resets.scss";
import "./filters";

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
Vue.use(VuePageTransition);

let app;
auth.onAuthStateChanged(async (user) => {
  //fetch user
  if (user) {
    await store.dispatch("fetchUserProfile");
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }

  // if (store.getters.isAdmin)
  // await store.dispatch("getMembers");
  await store.dispatch("getPlays");
  await store.dispatch("getSettings");
});
