<template>
  <div class="search">
    <input
      v-model="query"
      type="search"
      name=""
      id=""
      placeholder="Search for a play"
      required
      @keypress.enter="handleSearch"
    />
    <svg viewBox="0 0 50 50">
      <path
        d="M21 3C11.601563 3 4 10.601563 4 20C4 29.398438 11.601563 37 21 37C24.355469 37 27.460938 36.015625 30.09375 34.34375L42.375 46.625L46.625 42.375L34.5 30.28125C36.679688 27.421875 38 23.878906 38 20C38 10.601563 30.398438 3 21 3 Z M 21 7C28.199219 7 34 12.800781 34 20C34 27.199219 28.199219 33 21 33C13.800781 33 8 27.199219 8 20C8 12.800781 13.800781 7 21 7Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  computed: {
    query: {
      get() {
        return this.$store.getters.query;
      },
      set(value) {
        this.$store.commit("SET_QUERY", value);
      },
    },
  },
  methods: {
    handleSearch() {
      if (!this.query || this.query.length < 3) return;
      if (this.$route.name !== "Search") this.$router.push({ name: "Search" });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  input {
    width: 100%;
    height: 35px;
    border-radius: 30px;
    outline: none;
    border: 1px solid #b3b3b3;
    background: transparent;
    padding: 12px;
    color: #fff;
    transition: 0.2s all ease;

    &::-webkit-search-cancel-button {
      position: relative;
      right: -10px;
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      background: url(~@/assets/cancel.svg);
      opacity: 0.5;
      z-index: 4;
      cursor: pointer;
      transition: 0.3s all ease;
      content: "X";
      color: white;

      &:hover {
        opacity: 1;
      }
    }

    &:active,
    &:focus {
      background: black;
    }
  }

  input:not(:placeholder-shown) + svg {
    opacity: 0;
  }

  svg {
    fill: #5b5b5b;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    height: 20px;
    transition: 0.3s all ease;
  }
}
</style>
