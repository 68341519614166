<template>
  <router-link
    v-if="isApproved"
    :to="{ name: 'PDF', params: { playId: play.id, play: play } }"
    class="btn green"
    >Read More</router-link
  >
  <router-link v-else-if="!isAuthenticated" :to="{ name: 'SignIn' }" class="btn"
    >Sign In to View More</router-link
  >

  <button
    v-else-if="!isApproved && user.requests.includes(play.id)"
    class="btn yellow"
    :class="{ requesting: loading }"
  >
    <template v-if="!loading">Waiting For Access</template>
  </button>

  <button
    v-else
    class="btn red"
    :class="{ requesting: loading }"
    @click="requestAccess"
  >
    <template v-if="!loading">Request Permission to View</template>
  </button>
</template>

<script>
import { mapGetters } from "vuex";
import { userCollections, firebase, auth } from "@/firebase-config";
import axios from "axios";
export default {
  props: {
    play: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "isAuthenticated", "user"]),
    isApproved() {
      return this.$store.getters.isApproved(this.play.id);
    },
  },
  methods: {
    async requestAccess() {
      this.loading = true;
      try {
        const data = {
          User: this.user.displayName,
          Email: this.user.email,
          Phone_Number: this.user.phoneNumber,
          Play: this.play.name,
          Request_Type: "Access to view play",
        };
        await axios.post("https://formspree.io/f/myyleokz", {
          data,
        });
        await userCollections.doc(auth.currentUser.uid).update({
          requests: firebase.firestore.FieldValue.arrayUnion(this.play.id),
        });

        alert("form submitted");
      } catch (err) {
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  all: unset;
  cursor: pointer;
  background: #e5e5e5;
  color: #3c4043;
  border-radius: 30px;
  padding: 10px 20px;
  display: inline-block;
  font-weight: 600;
  margin-top: 25px;
  transition: 0.2s all ease-in;

  &.red {
    background: red !important;
    color: #e5e5e5 !important;
  }

  &.green {
    background: green !important;
    color: #e5e5e5 !important;
  }

  &.yellow {
    background: gold !important;
    color: #3c4043 !important;
  }

  &.requesting {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: transparent !important;
    padding: 0 !important;
    border: 5px solid #e5e5e5 !important;
    border-top-color: turquoise !important;
    animation: rotate 1s cubic-bezier(0.86, 0, 0.07, 1) infinite !important;

    @keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }
  }

  &:hover {
    background: #3c4043 !important;
    color: #e5e5e5;
  }
}
</style>
