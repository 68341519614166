<template>
  <div id="no-match">
    <h1>
      <span>Oops.</span>
      <p v-html="text"></p>
    </h1>
    <router-link v-if="showButton" :to="{ name: 'Scripts' }" class="btn"
      >Go Home</router-link
    >
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "No scripts match your search in our database.",
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  padding: 4vw;
  text-align: center;

  h1 {
    color: $light-grey;
    font-weight: normal;

    span {
      display: block;
      font-size: 10vw;
      font-weight: bold;
    }
  }

  .btn {
    margin-top: 20px;
    background: $light-grey;
    padding: 20px;
    border-radius: 100px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem;
    width: 250px;
    text-align: center;

    &:hover {
      background: $dark-grey;
    }
  }
}
</style>
